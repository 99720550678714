import React from "react"

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
    <div className="video embed-responsive embed-responsive-16by9" style={{width:"100%"}}>
      <iframe
        className="embed-responsive-item"
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        width="100%" 
        height="100%" 
        display="initial"
      />
    </div>
  )

  export default Video