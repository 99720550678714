import React, { useState } from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import { Col, Row } from "react-bootstrap"
import Img from "gatsby-image"
import Video from "../components/video"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

const Project = () => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: {pieceDirectory: {eq: "elnaz 17082018 draws a circle"}, pieceType: {eq : "performance"}}) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  const lightboxImages = data.images.nodes.map(n => n.childImageSharp.full.src)
  const [index, setIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const prevIndex = index - (1 % lightboxImages.length)
  const nextIndex = (index + lightboxImages.length + 1) % lightboxImages.length
  return (
    <Layout>
      {isOpen ?
        <Lightbox
          mainSrc={lightboxImages[index]}
          nextSrc={lightboxImages[nextIndex]}
          prevSrc={lightboxImages[prevIndex]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setIndex(prevIndex)}
          onMoveNextRequest={() => setIndex(nextIndex)}
        />
        : ""}
      <SEO title="Inside and Outside" />
      <Row>
        <Col lg={2}>
          <b>Title : </b>Inside and Outside<br />
          <b>Year : </b>2018<br />
          <b>Material : </b>performance, clay and personal objects<br />
          <b>Duration : </b>7 minutes 14 seconds<br />

        </Col>

        <Col lg={{ span: 10 }} className="py-2">
          <Video
            videoSrcURL="https://www.youtube.com/embed/y3BRDFntpZ8"
            videoTitle="title"
          />
        </Col>
      </Row>
      {data.images.nodes.map((i, thumbIndex) =>
        <Row>
          <Col lg={{ span: 10, offset: 1 }} className="py-2">
            <a onClick={() => {
              setIndex(thumbIndex)
              setIsOpen(true)
            }}
            >

              <Img fluid={i.childImageSharp.fluid}
                style={{ "maxHeight": "calc(100vh - 4rem)" }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </a>
          </Col>
        </Row>
      )}
    </Layout>
  )
}

export default Project
